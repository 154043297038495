import { mapGetters } from "vuex";

export default {
    name: "success-order",
    props: {
        payload: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            isDistributor: "profile/isDistributor",
        })
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        getText() {
            let text = this.$t("thankYouForYourOrder");
            if (this.payload.withWebinar) {
                text += ` <br/> ${this.$t(
                    "afterPaymentIsConfirmedYouWillBeGivenAccessToTheTrainingInYourPersonalAccountInTheSectionMyOnlineTrainingPlatform"
                )}.`;
            }
            else if (this.payload.withSms) {
                text += `<br/> ${this.$t('expectConfirmationFromTheManagerAndPaymentDetails')}.`;
            }

            return text;
        },
    },
};
