import { some } from "lodash";
import typeSelect from "./components/select/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";

export default {
    props: {
        product: {
            type: Object,
            default: () => {
            },
        },
        hover: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        typeSelect,
    },
    name: "product",
    data() {
        return {
            isProductPriceInBasket: false,
            selectedProductPrice: null,
            favoriteSelect: false,
            basketKey: 1,
            leftoverType: null,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
            favourites: "favorites/whichList",
            basket: "basket/basket",
            user: "profile/user",
            showNotifyLoader: "products/showNotifyLoader",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            currentRegionCurrency: "regions/currentRegionCurrency",
        }),
    },
    watch: {
        selectedProductPrice: function () {
            this.$router.query = this.selectedProductPrice.value;
            this.selectProductPrice(this.selectedProductPrice);
        },
        basket: function () {
            this.basketKey += 1;
            this.checkProductInBasket();
        },
    },
    created() {
        if (this.product.productPrices) {
            let sorted = this.product.productPrices?.data.sort(this.compare);
            this.selectProductPrice(sorted[1] ? sorted[1] : sorted[0]);
        }
    },
    methods: {
        getProductPriceTitle,
        ...mapMutations({
            addToBasket: `basket/ADD_ITEM_TO_BASKET`,
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeFastBuyPopup: "popups/CHANGE_FAST_BUY_POPUP",
            changeBasketPopup: "popups/CHANGE_BASKET_POPUP",
            showNotifyWhenAvailable: "popups/SHOW_NOTIFY_WHEN_AVAILABLE",
            changeSeminarDetailsPopup: "popups/CHANGE_SEMINAR_DETAILS_POPUP",
        }),
        ...mapActions({
            addToFavorite: "favorites/ADD_PRODUCT_TO_FAVOURITES",
            removeFromFavorite: "favorites/REMOVE_PRODUCT_IN_FAVORITE",
            getFavorites: "favorites/GET_FAVORITES_LIST",
            fetchData: "products/SET_PRODUCT_WHEN_AVAILABLE",
        }),
        routeToProduct: routeToProduct,
        resolveProductImage: resolveProductImage,
        addDataLayer() {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: "select_item",
                ecommerce: {
                    item_list_id: "Cosmetics",
                    item_list_name: "Cosmetics",
                    items: [
                        {
                            item_id: this.product.productPrices.data[0].article,
                            item_name: this.product.title,
                            affiliation: "Renew-Cosmetics",
                            coupon: "",
                            discount: 0.00,
                            index: 0,
                            item_brand: this.product?.brand?.data?.title || 'Renew',
                            item_category: this.product?.brandSeries?.data?.title || 'Makeup removal',
                            item_category2: (() => {
                                if (!this.isAuthenticated) return 'unauthorized'
                                if (this.isProfessional) return 'professional'
                                if (this.isDistributor) return 'distributor'
                                return 'regular'
                            })(),
                            item_list_id: "Cosmetics",
                            item_list_name: "Cosmetics",
                            location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                            price: this.product.productPrices.data[0].price,
                            quantity: 1,
                        },
                    ],
                },
            });

        },
        /**
         * @param {ProductPrice} productPrice
         */
        selectProductPrice(productPrice) {
            this.selectedProductPrice = productPrice;
            this.leftoverType = productPrice.leftoverType.data;
            this.checkProductInBasket();
        },
        compare(a, b) {
            // Use toUpperCase() to ignore character casing
            const bandA = a.value;
            const bandB = b.value;

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        },
        openFastBuy() {
            this.changeFastBuyPopup(true, {
                product: {
                    select_count: 1,
                    select_type: this.selectedProductPrice,
                    ...this.product,
                },
            });
        },
        checkProductInBasket() {
            this.basketKey += 2;
            this.isProductPriceInBasket = some(this.basket, { select_type: { id: this.selectedProductPrice.id } });
        },
        addBasket() {
            if (this.product.hasDuplicate) {
                this.$toasted.info(this.$t("thisProductHasBeenMovedToANewSeriesAndCannotBePurchased"));
                return;
            }

            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: this.currentRegionCurrency || "EUR",
                    value: this.selectedProductPrice.price,
                    items: [
                        {
                            item_id: this.selectedProductPrice.article,
                            item_name: this.product.title,
                            affiliation: "Renew-Cosmetics",
                            coupon: "",
                            discount: 0.00,
                            index: 0,
                            item_brand: this.product?.brand?.data?.title || 'Renew',
                            item_category: this.product?.brandSeries?.data?.title || 'Makeup removal',
                            item_category2: (() => {
                                if (!this.isAuthenticated) return 'unauthorized'
                                if (this.isProfessional) return 'professional'
                                if (this.isDistributor) return 'distributor'
                                return 'regular'
                            })(),
                            item_list_id: "Cosmetics",
                            item_list_name: "Cosmetics",
                            location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                            price: this.selectedProductPrice.price,
                            quantity: 1,
                        },
                    ],
                },
            })

            this.addToBasket({
                select_count: 1,
                product_price: this.selectedProductPrice,
                product: this.product,
            });
            this.changeBasketPopup(true);
            this.checkProductInBasket();
            // this.$toasted.success(this.$t('successAddBasket'));
        },
        showNotify() {
            const showPopup = () => {
                this.changeSeminarDetailsPopup({
                    status: true, payload: {
                        title: this.$t("thankYouForContactingUsAManagerWillContactYouSoonTitle"),
                        detail: this.$t("thankYouForContactingUsAManagerWillContactYouSoon"),
                    },
                });
            };

            const payload = {
                product_id: this.product.id,
                product_price_id: this.selectedProductPrice.id,
            };

            if (!this.isAuthenticated) {
                this.showNotifyWhenAvailable({ status: true, payload });
            } else {
                this.fetchData(payload)
                    .then(showPopup)
                    .catch(showPopup);
            }
        },
        changeFavorite() {
            if (this.isAuthenticated) {
                const index = this.favourites.find(e => e.productId === this.product.id);
                if (index) {
                    this.removeFromFavorite(index.id).then(() => {
                        this.getFavorites();
                        this.favoriteSelect = false;
                        this.$toasted.success(this.$t("successRemoveFromFavorite"));
                    });
                } else {
                    this.addToFavorite({ product_id: this.product.id }).then(() => {
                        this.favoriteSelect = true;
                        this.$toasted.success(this.$t("successAddToFavorites"));
                        this.getFavorites();
                    });
                }
            } else {
                this.changeLoginPopup(true);
            }
        },
    },
};
