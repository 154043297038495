import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation'
import {globalLoader} from '@/helpers/variables'

import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {NavigationItem} from "@/types/site";
import type {VariablesData} from "@/types/api";

interface State {
  config: any | null;
  configLoading: boolean;
  locales: any | null;
  translations: any | null;
  variables: VariablesData | null;
  headerMenu: Array<NavigationItem>;
  salesHeaderMenu: Array<NavigationItem>;
}

const state: State = {
  config: null,
  configLoading: false,
  locales: null,
  translations: null,
  variables: null,
  headerMenu: [],
  salesHeaderMenu: [],
};

const getters = {
  variables: (state: State) => state.variables,
  locales: (state: State) => state.locales,
  translations: (state: State) => state.translations,
  config: (state: State) => state.config,
  configLoading: (state: State) => state.configLoading,
  headerMenu: (state: State) => state.headerMenu,
  salesHeaderMenu: (state: State) => state.salesHeaderMenu,
};

const actions = {
  GET_INSTAGRAM_GALLERY: async () => {
    try {
      const response = await $http.post(`https://api.instagram.com/oauth/access_token/client_id=1089452858439752/client_secret=8d9d4a56c5bb56473a42369650e28bf2/grant_type=authorization_code/redirect_uri=https://frontend.alphascience.hdit.info/code=AQAaDwHLoUlV67KLnnp3OId1JJmoyuACk6-3S6IJOCaMXneesic9HIcm5uBP2QHNSRtLIQHHMGlQoUyFsCmTTC18j1E3KuLxt6E7wv8mFzcCT-BG-aVNY6vxeLrsCHaRSmxiJO4bvQo7RLoI1-h8MCnaVTHda2kRBv41Jd96_vbbKq8LL_5VA9Y8TIFCE0_qKyrkccuKUmZqubx_H0Vn7H17IlBGv2gVG03xzyorBd7NrQ`);
      // commit('SET_HEADER_MENU', response.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_HEADER_MENU: async ({commit}: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get('v1/menus');
      commit('SET_HEADER_MENU', response.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_SALES_HEADER_MENU: async ({commit}: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get('v1/sales/products');
      commit('SET_SALES_HEADER_MENU', response.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_VARIABLES: async ({commit}: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get('v1/variables');
      commit('SET_VARIABLES', response.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_LANGUAGE_LIST: async ({commit}: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get('v1/faq');
      commit('SET_LANGUAGE_LIST', response.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_TRANSLATIONS: async ({commit}: ActionContext<State, RootState>, translationKey: string) => {
    globalLoader(true);
    try {
      const response = await $http.get('v1/translations');
      // @ts-ignore
      let result = Object.assign(response.data, translation[window.currentLocale ? window.currentLocale : translationKey]);
      commit('SET_TRANSLATIONS', result);
      return result;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  SEND_FEEDBACK: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    try {
      await $http.post('v1/store_feedback_form', payload);
    } catch (e) {
      throw e;
    }
  },

};

const mutations = {
  SET_HEADER_MENU(state: State, list: Array<NavigationItem>) {
    state.headerMenu = list;
  },
  SET_SALES_HEADER_MENU(state: State, list: Array<NavigationItem>) {
    state.salesHeaderMenu = list;
  },
  SET_VARIABLES(state: State, status: any) {
    state.variables = status;
  },
  SET_LANGUAGE_LIST(state: State, status: any) {
    state.locales = status;
  },
  SET_TRANSLATIONS(state: State, status: any) {
    // state.translations = null
    state.translations = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
