<template>
    <div class="before-after">
        <div class="before-after__wrap">
            <div class="before-after__product">
                <img :src="info.product.image" alt="" />
            </div>
            <div class="before-after__content">
                <div class="before-after__images" v-for="item in info.items.data" :key="item.id">
                    <template v-if="item.afterImage.length > 0">
                        <div class="before-after__desc">
                            <h2 v-if="item.title">{{ item.title }}</h2>
                            <p v-if="item.description" v-html="item.description"></p>
                        </div>
                        <div class="before-after__item-wrap">
                            <div class="before-after__item">
                                <div class="before-after__item-img">
                                    <img :src="item.beforeImage" alt="" />
                                </div>
                                <div class="before-after__item-text">
                                    {{ $t("before") }}
                                </div>
                            </div>
                            <div class="before-after__item">
                                <div class="before-after__item-img">
                                    <img :src="item.afterImage" alt="" />
                                </div>
                                <div class="before-after__item-text">
                                    {{ $t("after") }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="before-after__desc">
                            <h2 v-if="item.title">{{ item.title }}</h2>
                            <p v-if="item.description" v-html="item.description"></p>
                        </div>
                        <div class="before-after__item">
                            <div class="before-after__item-img large">
                                <img :src="item.beforeImage" alt="" />
                            </div>
                            <div class="before-after__item-text ">
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { resolveProductImage } from "@/helpers";

export default {
    name: "before-after",
    methods: { resolveProductImage },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
.before-after {
    &__wrap {
        display: flex;
        justify-content: space-between;
    }

    &__content {
        max-width: 630px;
        width: 100%;
        margin-left: 50px;

        &-title {
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            margin-bottom: 50px;
            margin-left: 50px;
        }
    }

    &__images {
        display: flex;
        flex-direction: column;
    }

    &__product {
        width: 529px;
        height: 529px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__images {
        //margin-left: 50px;

        .before-after__desc {
            h2 {
                font-weight: normal;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
                margin-bottom: 0;
                //margin-left: 50px;
                text-transform: uppercase;
                width: 100%;
            }

            p {
                width: 100%;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-wrap {
            display: flex;
        }

        &:not(:last-child) {
            margin-right: 30px;
        }

        &-text {
            margin-top: 15px;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #000000;
        }

        .large {
            max-width: 630px;
            width: 100%;
        }

        &-img {
            width: 300px;
            height: 440px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 910px) {
    .before-after__wrap {
        flex-direction: column;
    }
    .before-after__product {
        width: 100%;

        img {
        }
    }
    .before-after__images {
        width: 100%;
        justify-content: center;
        margin-left: 0;
    }
    .before-after__item-img {
        width: 100%;
        //width: 150px;
        //height: 205px;
    }
    .before-after__item-wrap {
        width: 100%;
        justify-content: space-between;

        .before-after__item {
            width: 50%;
        }
    }
    .before-after__content {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        //width: 265px;
        //height: 265px;
    }
    .before-after__content-title {
        margin-top: 15px;
        margin-left: 0;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .before-after__item-img {
        height: auto;
    }
    .before-after__item-img img {
        object-fit: fill;
    }
    .before-after__product {
        height: auto;

        img {
            height: auto;
        }
    }
}
</style>
