import { allCountries } from 'vue-phone-mask-input/src/country_telephone_data'
import { helpers } from "vuelidate/lib/validators";

export const phoneNumberValidation = helpers.withParams(
    { message: 'phoneNumberIsNotValid' },
    value => {
      console.log('test', value, isNumberFullEntered(value))
      return isNumberFullEntered(value)
    }
)

export const latinOnly = helpers.regex('latinOnly', /^[a-zA-Z\sáÁčČďĎéÉěĚíÍňŇóÓřŘšŠťŤúÚůŮýÝžŽ]*$/);
export const latinOnlyWithSymbols = helpers.regex('latinOnlyWithSymbols', /^[a-zA-Z0-9\s,.\/\\_|řŘžŽýÝáÁíÍéÉúÚůŮňŇšŠčěEČďĎťŤóÓĚ-]*$/);


export const isNumberFullEntered = (number) => {
  const mask = getMaskForNumber(number)
  if (!mask) return false
  const numberLength = number ? (number.match(/\d/g) || []).length : 0;
  const maskNumberLength = (mask.match(/\./g) || []).length;
  return mask && numberLength >= maskNumberLength
}

export const getMaskForNumber = (number) => {
  if (typeof number !== 'string' || number.length === 0) {
    return null
  }
  const phoneNumber = number.replaceAll(/\D/g, '');
  for (const country of allCountries) {
    const countryCode = country[2]
    const mask = country[3]

    if (phoneNumber.startsWith(countryCode)) {
      return mask
    }
  }

  return null
}


export default {
  methods: {
    computeErrors(searchIn, field, validators) {
      let error = []
      let validationField = this.$v[searchIn][field] || {}

      if (!validationField.$dirty) return error
      for (let key in validators) {
        if (!validationField[key]) {
          if (typeof validators[key] === 'string') {
            error.push(this.$t(validators[key]))
          } else {
            error.push(this.$t(validators[key].message).replace(':count', validators[key].count))
          }
        }
      }

      if (this.validationErrors[field]) {
        this.validationErrors[field].forEach(row => {
          error.push(row)
        })
      }

      return error
    },
  },
}
